import { GetPid } from "./Util.js";
/*
PID정리

일반 페이지
0: Main.js
1: Introduction.js
2: Gallery.js
5: DataRoom.js
7: Help.js

로그인 관련
3: Login.js
4: Logout.js
6: Register.js
 */

// page import
import Main from './pages/Main';
import Error404 from './pages/Error404';
import DataRoom from './pages/DataRoom';
import Gallery from './pages/Gallery';
import Introduction from './pages/Introduction';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Register from './pages/Register';
import Help from './pages/Help.js';

function App() {
  let page;
  switch (GetPid()) {
    case "0": page = (<Main/>); break;
    case "1": page = (<Introduction/>); break;
    case "2": page = (<Gallery/>); break;
    case "3": page = (<Login/>); break;
    case "4": page = (<Logout/>); break;
    case "5": page = (<DataRoom/>); break;
    case "6": page = (<Register/>); break;
    case "7": page = (<Help/>); break;
    default: page = (<Error404/>); break;
  }

  return (page);
}

export default App;
