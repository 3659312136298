import { TextField, Button, Box, Typography } from "@mui/material";
import { Redirect } from "../Util";
import * as React from "react";
import "../fonts.css";

//PID: 3
export default function Login() {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mt: 15,
          mb: 15,
        }}
      >
        <Button
          variant="outlined"
          sx={{
            color: "red",
            borderColor: "red",
            mr: 50,
            mb: 2,
          }}
          onClick={() => {
            window.history.back();
          }}
        >
          돌아가기
        </Button>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "rgb(192, 252, 255)",
            borderRadius: "10px",
            width: 500,
            boxShadow: 20,
          }}
        >
          <Typography
            sx={{ fontSize: "2.5rem", mt: 5, fontFamily: "yg-jalnan" }}
          >
            로그인
          </Typography>
          <TextField
            label="아이디"
            variant="outlined"
            sx={{ mt: 2, mb: 1, width: 300 }}
            required
          ></TextField>
          <TextField
            type="password"
            required
            label="비밀번호"
            variant="outlined"
            sx={{ width: 300 }}
          ></TextField>
          <Button
            variant="contained"
            sx={{
              width: 300,
              height: 50,
              fontSize: "1.2rem",
              mt: 5,
              fontFamily: "SeoulNamsanM",
              fontWeight: "bold",
            }}
          >
            로그인
          </Button>
          <h4 style={{ marginTop: 50, color: "grey" }}>계정이 없으신가요?</h4>
          <Button
            variant="outlined"
            sx={{
              weight: 50,
              width: 200,
              mb: 10,
              fontFamily: "SeoulNamsanM",
              fontWeight: "bold",
            }}
            onClick={() => {
              Redirect(".?pid=6", false);
            }}
          >
            회원가입
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
}
