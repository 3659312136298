import { Box, Typography } from '@mui/material';
import { Redirect } from '../Util';
import logo from '../files/topbar_logo.png';

export default function TopBar() {
    const items = [{
        name: '소개',
        href: '.?pid=1'
    }, {
        name: '갤러리',
        href: '.?pid=2'
    }, {
        name: '자료실',
        href: '.?pid=5'
    }, {
        name: '문의',
        href: '.?pid=7'
    }];

    let itemComponents = [];
    items.map((e) => {
        itemComponents.push(
            <Typography
                key={e.name}
                sx={
                    { mt: 3, ml: 3, fontSize: '1.2rem', fontFamily: 'SeoulNamsanM', cursor: 'pointer', '&:hover':{ color: 'gray' }}
                }
                onClick={() => { Redirect(e.href, false) } }>
                    {e.name}
                </Typography>
        )
        return null;
    });

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            borderBottom: '1px solid #D1D1D1',
            alignItems: 'center'
        }}>
            <img onClick={() => {Redirect('.', false)}}src={logo} alt="logo" style={{ width: 300, margin: 20, marginTop: 10, marginBottom: 10, cursor: 'pointer' }}/>
            <Typography sx={{ mt: 3, ml: 3, fontSize: '1.2rem', fontFamily: 'SeoulNamsanM', cursor: 'pointer', fontWeight: 'bold', color: '#71b581' }}>홈</Typography>
            { itemComponents }
        </Box>
    );
}