import { TextField, Button, Box, Typography, Divider, Checkbox, FormControlLabel } from "@mui/material";
import * as React from "react";
import "../fonts.css";

// PID: 6

export default function Register() {
    //RD = Register Diabled임
    let [Check, setCheck] = React.useState(false);
    let [Name, setName] = React.useState("");
    let [Password, setPassword] = React.useState("");
    let [SecretCode, setSecretCode] = React.useState("");

    function ChangeCheckbox() {
        setCheck(!Check)
    }

    return (
        <React.Fragment>
            <Box sx={{display:"flex", justifyContent:"center",alignItems:"center",mt:15,mb:15,flexDirection:"column"}}>
            <Button
                variant="outlined"
                sx={{
                    color: "red",
                    borderColor: "red",
                    mr: 63,
                    mb: 2,
                }}
                onClick={() => {
                    window.history.back();
                }}
                >
                돌아가기
                </Button>
                <Box sx={{display:"flex",justifyContent:"center",alignItems:"center", width:600,height:600, backgroundColor:"rgb(255, 220,220)",borderRadius:"10px",boxShadow:20,flexDirection:"column"}}>
                    <Typography sx={{fontFamily:"yg-jalnan",fontSize:"3.5rem",color:"rgb(50,50,50)"}}>
                        회원가입
                    </Typography>
                    <TextField variant="outlined" label="이름" sx={{mt:5, width:280}} onChange={(event)=>{setName(event.target.value)}}></TextField>
                    <TextField type="password" variant="outlined" label="비밀번호" sx={{mt:1, width:280}} onChange={(event)=>{setPassword(event.target.value)}}></TextField>
                    <Divider orientation="horizontal" flexItem sx={{mt:2, width:400, ml:12.5}} variant="middle"></Divider>
                    <TextField required variant="outlined" label="참가코드" sx={{mt:2,width:250}} onChange={(event)=>{setSecretCode(event.target.value)}}></TextField>
                    <FormControlLabel sx={{mt:0.5}} label="개인정보 수집 및 이용에 동의합니다." control={<Checkbox onClick={()=>{ChangeCheckbox()}}></Checkbox>}></FormControlLabel>
                    <Button variant="outlined" sx={{mt:2.5, fontFamily:"SeoulNamsanM", fontWeight:"bold", width:250,height:50, fontSize:"1.5rem"}} disabled={!(Check&&Name!==""&&Password!==""&&SecretCode!=="")}>가입하기</Button>
                </Box>
            </Box>
        </React.Fragment>
    );
}