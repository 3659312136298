import * as React from 'react';
import { Box } from '@mui/material';

import Topbar from '../components/Topbar';

// PID: 0
export default function Main() {
    return (
        <React.Fragment>
            <Topbar/>
            <Box>

            </Box>
        </React.Fragment>
    );
}