export function Redirect(target, noHistory) {
    (noHistory) ? window.location.replace(target) : window.location.assign(target);
}

export function GetPid() {
    const urlParameter = new URL(window.location.href).searchParams;
    let pageId = urlParameter.get('pid');
    if (pageId === null) pageId = '0';

    return pageId;
}